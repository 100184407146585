<div id="agency_invoice">
    <div *ngIf="data.negative_credit" id="background">
        UNPAID INVOICE DUE TO NEGATIVE BALANCE
    </div>
    <!-- CONTAINER -->
    <div class="container" #container>

        <!-- BODT & HEADER -->
        <div class="head-and-body">

            <!-- HEADER -->
            <div id="header" class="flex justify-between">
                <div class="header-text">
                    <div *ngIf="data.finalized_at" class="title">
                        <span class="title-facture field-name">Facture</span>
                        <span class="num-factue f-bold">{{data.serial_number}}</span>
                    </div>

                    <div *ngIf="data.status == 'draft'">
                        <span class="field-name font-black col-red">Facture Non Finalisé !</span>
                    </div>

                    <br>

                    <div class="space">
                        <span class="field-name text-gray-900 font-black text-xl ">Client</span>
                        <span class="text-gray-800 font-medium text-xl text-right">{{data.invoice_name}}</span>
                    </div>

                    <div class="space">
                        <span class="field-name text-gray-900-900 font-black text-xl">Adresse</span>
                        <span class="text-gray-800 font-medium text-xl text-right">{{data.invoice_adresse}}
                            {{data.zip_code}}</span>
                    </div>

                    <div class="space">
                        <span class="field-name text-gray-900-900 font-black text-xl">Matricule Fiscal</span>
                        <span class="text-gray-800 font-bold text-xl ">{{data.tax_id}}</span>
                    </div>

                    <div *ngIf="data.status != 'draft'" class="space">
                        <span class="field-name text-gray-900 font-black text-xl">Date :</span>
                        <span class="text-gray-800 font-medium text-xl ">{{data.created_at | date}}</span>
                    </div>
                    
                </div>

                <div>
                    <img class="header-img" src="./assets/landing/logo_invoice.jpg" width="100px" alt="">
                </div>
            </div>
            <!-- HEADER -->

            <br>


            <!-- TABLE HEADER -->
            <div id="table-header" class="grid-6-cols">
                <div class="col-span-3">Description</div>
                <div class="text-right col-span-1">Prix unitaire</div>
                <div class="text-right col-span-1">Quantité</div>
                <div class="text-right col-span-1">Montant</div>
            </div>
            <!-- /TABLE HEADER -->



            <div class="ruler-container">
                <div class="ruler dark"></div>
            </div>

            <!-- Lines -->
            <ng-container *ngFor="let invoice_line of data.invoice_lines; let i = index">
                <div *ngIf="invoice_line.unit_price_line * invoice_line.quantity"
                    class="lines grid-6-cols">
                    <div class="flex items-center col-span-3">{{invoice_line.label_line }}{{getPeriodStart(invoice_line)}}</div>
                    <div class="t-right col-span-1">{{invoice_line.unit_price_line}}</div>
                    <div class="t-right col-span-1">{{invoice_line.quantity}}</div>
                    <div class="t-right col-span-1">{{invoice_line.total_line | number :'1.3-3'}} DT</div>
                </div>

            </ng-container>

            <!-- SOUS TOTAL -->
            <div class="lines grid-6-cols">
                <div class="col-span-4"></div>
                <div class="t-right col-span-1 col-black f-black">Total</div>
                <div class="t-right col-span-1 col-black f-black">{{data.total_ht | number :'1.3-3'}} DT</div>
            </div>
            <!-- /SOUS totalS -->


            <!-- <div class="relative grid grid-6-cols py-2 items-center">
                <div class="col-span-3 col-start-4 border-t-2 border-gray-300"></div>
            </div> -->

            <div class="ruler-container right">
                <div class="ruler light"></div>
            </div>



            <!-- TOTAL REMISE ? it contains both credit used and bonus assigned--> 
            <div class="lines grid-6-cols" *ngIf="data.total_discount + data.total_asgcredit_used">

                <div class="flex items-center col-span-4"></div>
                <div class="t-right f-black col-black col-span-1">
                            Total Remise
                </div>
                <div class="t-right f-black col-black col-span-1">
                        {{data.total_discount + data.total_asgcredit_used | number :'1.3-3'}} DT
                </div>
            </div>


        <!-- Lines Discounts -->
            <ng-container *ngFor="let invoice_line of data.invoice_lines; let i = index">
                <div *ngIf="invoice_line.amount_of"
                    class="lines grid-6-cols">
                    <div class="col-span-5 t-right">{{invoice_line.discount_description }}</div>
                    <div class="t-right col-span-1">
                        {{invoice_line.total_discount_line | number :'1.3-3'}} DT
                    </div>
                </div>

                <!-- No discount id so there is no description  -->
                <div *ngIf="!invoice_line.amount_of && invoice_line.total_discount_line" id="line-1"
                    class="lines grid-6-cols">
                    <div class="col-span-5 t-right">
                        <!--  {{invoice_line.discount_description ?? 'R( '+ invoice_line.label_line +' )'}} -->
                        <span *ngIf="invoice_line.unit_price_line * invoice_line.quantity">{{'R( '+
                            invoice_line.label_line +' )'}}</span>
                        <span
                            *ngIf="!(invoice_line.unit_price_line * invoice_line.quantity)">{{invoice_line.label_line}}</span>
                    </div>

                    <div class="t-right col-span-1">
                        {{invoice_line.total_discount_line | number :'1.3-3'}} DT
                    </div>
                </div>
            </ng-container>
        <!-- Lines Discounts -->


        <!-- <ng-container *ngFor="let balanceTransaction of data.invoice_BTs">

        </ng-container> -->
        <!-- Bonus -->
        <div *ngIf="data.invoice_bts?.bonus_sum" id="line-1"
            class="lines grid-6-cols">
            <div class="col-span-5 t-right">Balance bonus utilisé</div>
            <div class="t-right col-span-1">
                {{-data.invoice_bts.bonus_sum | number :'1.3-3'}} DT
            </div>
        </div>

        <!-- Bonus Refferal-->
        <div *ngIf="data.invoice_bts?.ref_sum" id="line-1"
            class="lines grid-6-cols">
            <div class="col-span-5 t-right">Credit parrainage utilisé</div>
            <div class="t-right col-span-1">
                {{data.invoice_bts.ref_sum | number :'1.3-3'}} DT
            </div>
        </div>






            <!-- CREDIT  -->

            <!-- <div (click)="openCreditDetails = !openCreditDetails" class="grid grid-cols-6 font-medium text-gray-700">

                <div class="flex items-center col-span-3"></div>
                <div class="justify-self-end self-center col-span-1"></div>
                <div class="justify-self-end self-center col-span-1 font-black text-right cursor-pointer">
                    <div class="flex justify-end items-center">
                        <mat-icon *ngIf="openCreditDetails" [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                        <mat-icon *ngIf="!openCreditDetails" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                        <span class="w-24 select-none">
                            Total Credit
                        </span>
                    </div>
                </div>
                <div class="justify-self-end flex items-center col-span-1">
                    <span>
                        -{{data.total_asgcredit_used | number :'1.3-3'}} DT
                    </span>
                </div>
            </div> -->

            

            <!--     //! ADD CREDIT NOTE CASE -->

            <!-- <div class="lines grid-6-cols">

                <div class="col-span-4"></div>
                <div class="col-span-1 f-black t-right">
                        Total Net HT
                </div>
                <div class="t-right col-span-1">
                        {{getTotalNetHt(data) | number :'1.3-3'}} DT
                </div>
            </div> -->
           
            <br>

            <div id="line-4"
                class="totals f-bold">
                <span class="t-right t-black">
                    Totals
                </span>
                <div class="relative flex py-5 items-center">
                    <div class="flex-grow border-t-2 border-gray-800"></div>
                </div>
               <!--  <div class="row">
                    <span>Montant Total HT</span>
                    <span class="t-right">{{data.total_ht | number : '1.3'}} DT</span>
                </div> -->
                <!-- <div class="flex justify-between">
                    <span>Remise HT</span>
                    <span>{{data.total_discount | number : '1.3'}} DT</span>
                </div> -->
                <div class="row">
                    <span>Total Net HT</span>
                    <span class="t-right">{{getTotalNetHt(data) | number : '1.3'}} DT</span>
                </div>
                <div class="row">
                    <span>Total TVA(19%)</span>
                    <span class="t-right">{{getTVA(data) | number : '1.3'}} DT</span>
                </div>
                <div *ngIf="getTimbre(data)" class="row">
                    <span>Timbre Fiscal</span>
                    <span class="t-right">{{getTimbre(data) | number : '1.3'}} DT</span>
                </div>

                <div class="row mt-2">
                    <span>Total TTC</span>
                    <span class="blue-background t-right">{{getTotalTTC(data) | number : '1.3'}}
                        DT</span>
                </div>

                <div class="ruler-container">
                    <div class="ruler dark"></div>
                </div>

                <div class="row t-black">
                    <span>Total Payé</span>
                    <span>
                        - {{data.total_paid | number : '1.3' }} DT
                    </span>
                </div>
                <div class="row t-black">
                    <span>Reste</span>
                    <span class="blue-background">
                        {{getRest(data) | number : '1.3'}} DT
                    </span>
                </div>



            </div>
        </div>

        <!-- <br>
        <br>
        <br> -->

        <div class="spacer"></div>

        
    </div>
    <div class="info-bottom px-20">
            <div class="ruler-container">
                <div class="ruler dark"></div>
            </div>
            <div class="grid-3-cols mx-">
        
                <!-- Header -->
                <div class="footer-header">STE iXnihilo SA</div>
                <div class="footer-header">Information de contact</div>
                <div class="footer-header">Information Bancaire</div>
        
                <!-- RAW 1 -->
                <div class="">000, Im Karray BLOC B</div>
                <div class="">56 923 666</div>
                <div class="">BANQUE de TUNSIE</div>


                <!-- RAW 2 -->
                <div class="">LAC ANNECY 1053 Tunis</div>
                <div class="">www.autoecoleplus.tn</div>
                <div class="">CODE BIC: BTBKTNTT</div>


                <!-- RAW 3 -->
                <div class="">Code TVA 1701529NAM000</div>
                <div class="">autoecoleplus.tn@gmail.com</div>
                <div class="">IBAN: TN59 0502 5000 0963 0017 8932</div>
        
            </div>
        
            <!-- <div class="relative flex py-2 items-center">
                        <div class="flex-grow border-t-4 border-gray-800"></div>
                    </div> -->
        
            <div class="center">MERCI POUR VOTRE CONDIANCE! - www.autoecoleplus.tn</div>
    </div>
    <div class="last-page"></div>
</div>