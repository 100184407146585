import { AuthentificationService } from './../main/authentification/authentification.service';
import { GlobalService } from "./../global.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { of, Subject } from "rxjs";
import { environment } from 'environments/environment';

@Injectable({
    providedIn: "root",
})
export class LayoutService {
    uri = environment.uriG
    scrollTo$ : Subject<any> = new Subject()

    constructor(
        private http: HttpClient,
        private _globalService: GlobalService,
        private deviceService: DeviceDetectorService,
        private _authService: AuthentificationService
    ) {}

    getNotifications() {
        if (
            this._globalService.getUser() &&
            this._globalService.getUser().role === "superadmin"
        ) {
            return of([]);
        } else {
            return this.http.get<any>(`${this.uri}/api/notifications`);
        }
    }

    get scrollTo(){
        return this.scrollTo$.asObservable()
    }

    set scrollTo(offset:any){
        this.scrollTo$.next(offset)
    }

    MarkQuestionsAsReaded() {
        return this.http.put<any>(
            `${this.uri}/api/notifications/questions`,
            {}
        );
    }

    notificationDelivered() {
        if (this._globalService.getUser().role === "superadmin") {
            return of([]);
        } else {
            return this.http.put<any>(
                `${this.uri}/api/notifications/delivered`,
                {}
            );
        }
    }

    notificationRed(id) {
        if (this._globalService.getUser().role === "superadmin") {
            return of([]);
        } else {
            return this.http.put<any>(
                `${this.uri}/api/notifications/red/${id}`,
                {}
            );
        }
    }

    getCurrent() {
        if (this._globalService.getUser().role === "superadmin") {
            return of(this._globalService.getUser());
        } else {
            return this.http.get<any>(`${this.uri}/api/employees/current`);
        }
    }
    getTutorial() {
        return this.http.get<any>(`${this.uri}/api/agences/tutorialState`);
    }
    updateTutorial(video) {
        return this.http.put<any>(
            `${this.uri}/api/agences/tutorialDone/${video}`,
            {}
        );
    }
    supprimerTutorial() {
        return this.http.delete<any>(
            `${this.uri}/api/agences/supprimerTutorial`
        );
    }

    detectdevice() {
        return this.deviceService.getDeviceInfo().os;
    }
}
