import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavbarService } from "./layout/components/navbar/navbar.service";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import io from "socket.io-client";
import { SwPush } from '@angular/service-worker';
import { take } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class RealTimeService {

    r = Math.random();
    j = 0;
    readonly VAPID_PUBLIC_KEY = "BGDJZDMHtY_I1FTHJOr45-FIQW1DFaavvDFUnrjXGnWNosXP361gKxptvHnukyY8f5SdbZVZh9Rl0wcs217RVCQ";

    socket;

    //* if we are in production but still in localhost
    urlSocket = !location.origin.includes('localhost') ?  environment.urlSocket : "http://localhost:3005/Agence";
    pushSubscription = null;
    urlRealTime = environment.urlRealTime + "/account/";
    ip = 'undefined';
    timer = null;
    id = null;
    agenceId = null;

    realTimeFollowUps: Subject<any> = new Subject<any>();
    realTimaQuestion: Subject<any> = new Subject<any>();
    realTimaNotification: Subject<any> = new Subject<any>();

    constructor(private navbarService: NavbarService,
        private swPush: SwPush,
        private http: HttpClient,
        ) {

        }

    

    connectSocket(id, agenceId, role) {
       if(environment.production){
        this.id = id;
        this.agenceId = agenceId;
        if (!this.socket) {
            this.socket = io(this.urlSocket, {
                secure:true,
                rejectUnauthorized: false,
                query: {
                    "userId":id,
                    "agenceId":agenceId,
                    "role":role   
                }
            });
            //`userId=${id}&agenceId=${agenceId}&role=${role}`,

            this.socket.on("msg", (msg) => {
            });

            this.socket.on("notification", (msg) => {
                console.log("notification")
            });

            // ? listen to question Notifications
            this.socket.on("follow-up", (followUP) => {
                this.realTimeFollowUps.next(followUP);
            });

            // ? listen to question Notifications
            this.socket.on("question", (question) => {
                let x = this.navbarService.getBadgeCount("Candidat-Code");
                x++;
                this.navbarService.setBadgeValue("Candidat-Code", {
                    title: "" + x,
                    fg: "white",
                    bg: "red",
                });
                this.navbarService.setChildBadgeValue(
                    "Candidat-Code",
                    "Candidat-Questions",
                    {
                        title: "" + x,
                        fg: "white",
                        bg: "red",
                    }
                );
                this.realTimaQuestion.next(question);
            });

            // ? listen to other Notifications
            this.socket.on("notification-ready", (notification) => {
                this.realTimaNotification.next(notification);
            });
        }
        this.startPing(id,agenceId);
        this.loadIp();
    }}

    disconnectNotification(){
        this.id = null;
        this.agenceId = null;
        this.disconnectSocket();
        this.unsubscribeFromPushNotification();
        this.stopPing();
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
            //this.socket.emit("disconnect");
            this.socket = null;
        }
    }

    getRealTimeQuestion() {
        return this.realTimaQuestion.asObservable();
    }

    getRealTimeFollowUps() {
        return this.realTimeFollowUps.asObservable();
    }

    getRealTimeNotification() {
        return this.realTimaNotification.asObservable();
    }

    subscribeToNotifications(compte_id) {

        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then(sub => 
            {
                console.log('swPushsub',sub);
                let subscription = {compte_id:compte_id,subscription:sub};
                this.pushSubscription = subscription;

                this.addPushSubscriber(subscription).subscribe(res=>{
                    console.log('swPushres',res);

                    this.swPush.notificationClicks.subscribe( arg =>
                        {
                          console.log('swPusharg',arg);
                       });
                })
            }
            )
        .catch(err => {console.log('swpush',err)});
    }

    //? NOTIFICATION SUBS
    unsubscribeFromPushNotification(){
        let params = new HttpParams();
        if(this.pushSubscription){
        //params = params.append("endpointUrl", this.pushSubscription.subscription.endpoint);
            console.log(this.pushSubscription.subscription.endpoint)

            this.http.delete(`${this.urlRealTime}pushservice`,{params: {endpointUrl:this.pushSubscription.subscription.endpoint}})
                .subscribe({next:res=>{
                    return
                },
                error:err=>{
                   // console.log('ERRfirst :',err)
                }})
        }
    }


    

    addPushSubscriber(e){
        console.log('e',e)
        return this.http.post(`${this.urlRealTime}pushservice`,e)
    }

    //? SESSION 

    loadIp(){
        this.http.get("https://api.ipify.org/?format=json")
          .subscribe({next:(response:any)=>{
            this.ip = response.ip     
        },error:(err) => {
          return
        }})
      }
      
      ping(id,agenceId){
        this.http.post(`${this.urlRealTime}session-ping/${id}/${this.ip}/${agenceId}`,{},{ withCredentials: true })
        .pipe(take(1))
        .subscribe(
            {
                next: () => {  },
                error: () => { return },    
            }
            )
      }
    
      startPing(id,agenceId){
        //console.log('STARTING PING');

        setTimeout(()=>{this.ping(id,agenceId)},2000) ;
        this.timer = setInterval(()=>{this.ping(id,agenceId)},50000)
      }
    
      stopPing() {
        //console.log('STOPING PING');

        if(this.timer){
          clearInterval(this.timer)
          this.timer = null;
          //! if there is more then one active tab just activeTab--
          this.http.post(`${this.urlRealTime}session-destroy`,{},{ withCredentials: true })
            .pipe(take(1))
            .subscribe(
                {
                    next: () => {  },
                    error: () => { return },    
                }
                )
            }
      }

      holdPing() {
        clearInterval(this.timer)
        this.timer = null;
      }
    
      resumePinging() {

        if(!this.timer && this.id){
            this.startPing(this.id,this.agenceId)
        }
      }    

}
