import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        type: "group",
        icon: "apps",
        children: [
            {
                id: "dash",
                title: "Acceuil",
                arabic: "(الاستقبال)",
                type: "item",
                icon: "dashboard",
                url: "/dashboard/analytics",
            },
            {
                id: "candidats",
                title: "Candidats",
                arabic: "(المترشحون)",
                type: "item",
                icon: "person",
                url: "/candidats",
            },
            {
                id: "online-services",
                title: "Services en ligne",
                arabic: "",
                type: "item",
                icon: "trending_up",
                url: "/online-services/list",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
            },

            /*
            {
                id: "Contact",
                title: "Contact",
                arabic: "(الإتصلات)",
                type: "item",
                icon: "contacts",
                url: "/contact",
            }, */
            {
                id: "Candidat-Code",
                title: "Suivi en ligne",
                arabic: "",
                type: "collapsable",
                icon: "school",
                children: [
                    {
                        id: "Candidat-Questions",
                        title: "Questions",
                        arabic: "",
                        type: "item",
                        icon: "forum",
                        url: "/code/questions",
                    },
                    {
                        id: "Candidat-series",
                        title: "Series passées",
                        arabic: "",
                        type: "item",
                        icon: "receipt_long",
                        url: "/code/learning",
                    },
                ],
            },
            {
                id: "calendar",
                title: "Calendrier ",
                arabic: "(الجدول)",
                type: "item",
                icon: "today",
                url: "/calendar",
            },
            {
                id: "ress",
                title: "Résérvations",
                arabic: "(الحجز)",
                type: "collapsable",
                icon: "checklist",
                children: [
                    {
                        id: "Reservation",
                        title: "Séances",
                        arabic: "(حصة)",
                        type: "item",
                        url: "/reservations",
                    },
                    {
                        id: "Reservation",
                        title: "Test",
                        arabic: "(تجربة)",
                        type: "item",
                        url: "/reservations-test",
                    },
                ],
            },
            {
                id: "finance",
                title: "Comptabilité",
                arabic: "(الحسابات)",
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "recettes",
                        title: "Recette ",
                        arabic: "(المداخيل)",
                        type: "item",
                        url: "/finance/recettes",
                    },
                    {
                        id: "depenses ",
                        title: "Dépenses",
                        arabic: "(المصاريف)",
                        type: "item",
                        url: "/finance/depenses/historique",
                    },
                    {
                        id: "project",
                        title: "Revenus ",
                        arabic: "(المرابيح)",
                        type: "item",
                        url: "/finance/revenus",
                    },
                ],
            },

            {
                id: "eqp",
                title: "Administration",
                arabic: "(الإدارة)",
                type: "collapsable",
                icon: "groups",
                function: () => {
                    window.scrollTo(0, document.body.scrollHeight);
                },
                children: [
                    {
                        id: "paramAgence",
                        title: "Mon Ecole",
                        arabic: "(المدرسة)",
                        type: "item",
                        icon: "business",
                        url: "/administration/agence",
                        exactMatch: true,
                    },
                    {
                        id: "compte",
                        title: "Mon Compte",
                        arabic: "(الحساب)",
                        type: "item",
                        url: "/administration/compte",
                        icon: "person_pin",
                        exactMatch: true,
                    },

                    {
                        id: "vhl",
                        title: "Véhicules",
                        arabic: "(العربات)",
                        type: "item",
                        icon: "directions_car",
                        url: "/ressources/vehicules",
                        exactMatch: true,
                    },
                    {
                        id: "listMb",
                        title: "Equipe",
                        arabic: "(فريق العمل)",
                        type: "item",
                        icon: "groups",
                        url: "/administration/equipe",
                        exactMatch: true,
                    },
                    {
                        id: "rs",
                        title: "Ressource & Locaux",
                        arabic: "(المقر)",
                        type: "item",
                        url: "/ressources/locaux",
                        exactMatch: true,
                    },
                    {
                        id: "paiementAgence",
                        title: "Facturation",
                        arabic: "(الفواتير)",
                        icon: "payments",
                        type: "item",
                        url: "/administration/contract",
                        exactMatch: true,
                    },
                ],
            },
            {
                id: "help",
                title: "Aide",
                arabic: "(مساعدة)",
                type: "item",
                icon: "help",
                url: "/help/knowledge/general-info",
                exactMatch: true,
                /* children: [
                          {
                              id: "base knowledge",
                              title: "Base Knowledge",
                              arabic: "(مساعدة)",
                              type: "item",
                              url: "/help/knowledge",
                              exactMatch: true,
                          },
                          {
                              id: "faq",
                              title: "Faq",
                              arabic: "(مساعدة)",
                              type: "item",
                              url: "/help/faq",
                              exactMatch: true,
                          },
                      ], */
            },
        ],
    },
];
