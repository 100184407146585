<div fxLayout="row" fxLayoutAlign="space-between start">
    <h3 style="font-size: 24px; font-weight: bold">Notifications</h3>
</div>
<ng-container *ngIf="notifications && notifications.length == 0">
    <p class="p-4">Pas de notifications</p>
</ng-container>
<div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    *ngFor="let notification of notifications"
>
    <div
        *ngIf="notification.type === 'notification'"
        class="p-8 flex"
        [ngClass]="{ bgBlue: !notification.opened }"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="5px"
    >
        <ng-container>
            <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="w-100-p"
            >
                <!-- <mat-icon style='width: 40px' class="fs-35 mr-8">account_circle</mat-icon> -->

                <div>
                    <span
                        class="fs-13"
                        [innerHTML]="notification.message"
                    ></span>
                    <br />
                    <span class="secondary-text mr-8 fs-12">{{
                        getDate(notification)
                    }}</span>
                </div>

                <button
                    style="width: 20px; margin-right: 20px"
                    mat-icon-button
                    aria-label="Mark as red"
                    (click)="markRed(notification.id)"
                >
                    <mat-icon class="fs-15">remove_red_eye</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
    <div
        *ngIf="notification.type === 'question'"
        [ngClass]="{ bgBlue: !notification.readed }"
        class="p-8 flex"
        style="cursor: pointer"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="5px"
        (click)="goToQuestion()"
    >
        <ng-container>
            <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="w-100-p"
            >
                <!-- <mat-icon style='width: 40px' class="fs-35 mr-8">account_circle</mat-icon> -->

                <div>
                    <span class="fs-13" *ngIf="notification.first_name"><strong>{{notification.first_name + " " + notification.last_name + " :"}}</strong>{{ notification.text }}</span>
                    <span class="fs-13" *ngIf="!notification.first_name">{{ notification.text }}</span>
                    <br />
                    <span class="secondary-text mr-8 fs-12">{{
                        getDate(notification)
                    }}</span>
                </div>

                <!-- <button style='width: 20px' mat-icon-button aria-label="Mark as red" (click)='markRed(notification.id)'>
                    <mat-icon class="fs-15">remove_red_eye</mat-icon>
                </button> -->
            </div>
        </ng-container>
    </div>

    <mat-divider class="flex"></mat-divider>
</div>
