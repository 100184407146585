import { CalendarService } from "./calendar.service";
import { EventLoadingService } from "./event-loading.service";
import { EventFormComponent } from "./event-form/event-form.component";
import { GlobalService } from "./../../../global.service";
import { Injectable } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { EventChoiceComponent } from "./event-choice/event-choice.component";
import { merge, Subject, Observable, take, forkJoin } from "rxjs";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { first } from "rxjs";
import { ReservationDialogComponent } from "../components/reservation-dialog/reservation-dialog.component";
import * as moment from "moment";
import { Router } from "@angular/router";

export interface Data {
    blockedDuration?:number
    renouvelement?: number
    frai?: number
    tarif?: any[]
    codeEx?: boolean;
    codeLess?: boolean;
    condEx?: boolean;
    condLess?: boolean;
    available: boolean;
    agents?: any[];
    agent?: number;
    cands?: any[];
    centers?: any[];
    cand?: number;
    vehicules?: any[];
    vehicule?: number;
    action: "new" | "edit";
    title: string;
    //? to set in the event form
    titre?: string;
    event?: any;
    date?: any;
    categorie: "codeEx" | "codeLess" | "conduiteEx" | "conduiteLess" | "busy";
    role?: "moniteur" | "agent bureau" | "administrateur" | "Agent bureau";
    start?: string;
    end?: string;
    fromCand?: boolean;
    hasFrai?: boolean;
    center?: number;
    cond_type?: null | 'circuit' | 'parc' | 'recyclage'
}

@Injectable({
    providedIn: "root",
})
export class EventFlowService {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent> = null;
    public dialogRef: any;
    public eventDialogRef: MatDialogRef<EventFormComponent> = null;
    public reservationDialogRef:MatDialogRef<ReservationDialogComponent> = null;
    public choseDialogRef: MatDialogRef<EventChoiceComponent> = null;

    private _eventDeleteAction: Subject<any>;
    private _eventSaveAction: Subject<any>;
    private _eventDialogIsOpen: Subject<any>;

    constructor(
        private router:Router,
        private _calendarService: CalendarService,
        private loadingService: EventLoadingService,
        private globalService: GlobalService,
        private _matDialog: MatDialog,

        //private _reservationDialogService: ReservationDialogComponent
    ) {

        this._eventSaveAction = new Subject();
        this._eventDeleteAction = new Subject();
        this._eventDialogIsOpen = new Subject();
        /* this._refresh = new Subject();
        this._dashAction = new Subject();
        this._calendarAction = new Subject();
        this._candAction = new Subject(); */
    }

    choice: any;

    get eventSave(): Observable<any> {
        return this._eventSaveAction.asObservable();
    }

    get eventDelete(): Observable<any> {
        return this._eventDeleteAction.asObservable();
    }

    get eventDialogIsOpen(): Observable<any> {
        return this._eventDialogIsOpen.asObservable();
    }

    deleteEvent(eventId) {
        if (this.confirmDialogRef === null) {
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: false,
                    closeOnNavigation: false
                }
            );

            this.confirmDialogRef.componentInstance.confirmMessage =
                "Vous voulez supprimer cet evenement ?";

            this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
                if (result) {
                    this._calendarService
                        .deleteEvent(eventId)
                        .subscribe((qres) => {
                            this._eventDeleteAction.next(eventId);
                        });
                }
                this.confirmDialogRef = null;
            });
        }
    }

    openChoiceForm(date) {
        this.choseDialogRef = this._matDialog.open(EventChoiceComponent, {
            panelClass:["common-dialog-style","event-choice-d-style"],
        });
        this.choseDialogRef.afterClosed().pipe(take(1)).subscribe((choice) => {
            this.choseDialogRef = null;
            
            if (choice) {
                setTimeout(() => {
                switch (choice) {
                    case "codeEx":
                        this.planExamCode(date, false);
                        break;
                    case "codeLess":
                        this.planLessCode(date);
                        break;
                    case "conduiteEx":
                        this.planExamConduite(null,date, false);
                        break;
                    case "conduiteExCircuit":
                        this.planExamConduite('circuit',date, false);
                        break;
                    case "conduiteExParc":
                        this.planExamConduite('parc',date, false);
                        break;
                    case "conduiteLess":
                        this.planLessConduite(null,date);
                        break;
                    case "conduiteLessCircuit":
                        this.planLessConduite('circuit',date);
                        break;
                    case "conduiteLessParc":
                        this.planLessConduite('parc',date);
                        break;
                    case "conduiteRecyclage":
                        this.planLessConduite('recyclage',date);
                        break;
                    case "busy":
                        this.planBusy(date);
                        break;
                }
            }, 200);
            }
        });
    }

    openEventForm(data) {
        if(this.eventDialogRef) return
        let isCalendar = this.router.url.indexOf('calendar') != -1;

        this.eventDialogRef = this._matDialog.open(EventFormComponent, {
            panelClass:["common-dialog-style","event-d-style"],
            data: data,
            disableClose: true,
            position: (window.innerWidth > 1099 && isCalendar) ? {bottom: '0px',right:'0px'} : {} 

            // closeOnNavigation: false
        });
        this.eventDialogRef.componentInstance.title = data.title;
        this._eventDialogIsOpen.next(true);
        this.eventDialogRef.afterClosed().pipe(take(1)).subscribe((res) => {
            this._eventDialogIsOpen.next(false)
            if (!res) this._eventSaveAction.next(null);
            else if (res[0] === "save") {
                this._eventSaveAction.next(res[1]);
            } else if (res[0] === "delete") {
                this.deleteEvent(res[1]);
            }
            //! check if we need to add the confirm shit inside -_-
            /* this.setDashAction(res[0]);
            this.setCandAction(res[0]);
            this.setCalendarAction(res[0]); */
            this.eventDialogRef = null;
        });
    }

    // !currently doesn't work because we removed reservations from the calendar
    // !Adjust Style if it's going to be activated again
    /* editReservation(event,title,fromReservation?){
        this.reservationDialogRef = this._matDialog.open(ReservationDialogComponent, {
            panelClass:["common-dialog-style"],
            width: "80%",
        data: event,
        });
        this.reservationDialogRef.componentInstance.title = "RESERVATION";
        this.reservationDialogRef.afterClosed().pipe(take(1)).subscribe((res) => {
            if (!res) this._eventSaveAction.next(null);
            else if (res[0] === "save") {
                this._eventSaveAction.next(res[1]);
            } else if (res[0] === "delete") {
                this.deleteEvent(res[1]);
            }
            //! check if we need to add the confirm shit inside -_-
            //this.setDashAction(res[0]);
            //this.setCandAction(res[0]);
            //this.setCalendarAction(res[0]); 
            this.eventDialogRef = null;
        });
    } */

    editBusy(event,title){
        let data: {};
        this.loadingService
            .getAllActiveAgents()
            .subscribe({
                next:(agents)=>{
                    console.log('agents',agents);
                    data = {
                        event: event,
                        condEx: false,
                        condLess: false,
                        categorie: event.type,
                        available: true,
                        action: "edit",
                        title: title,
                        fromCand: false,
                        cands: [],
                        cand: null,
                        agent: event.agentResp,
                        agents: agents,
                        canEdit: true,
                    };
                    this.openEventForm(data);
                },
                error:()=>{}
            })

    }

    editConduite(event, title, fromCandidat, candidat?) {
        let data: {};
        if (event.type === "conduiteEx") {
            this.loadingService
                .getAgents0Vehicles1Centers2ConduiteEx()
                .subscribe({
                    next:(arrays: any) => {
                        if (fromCandidat) {
                            if (event.completed) {
                                arrays[0].forEach((elem) => {
                                    if (elem.archived) {
                                        elem.username = "agent supprimé";
                                    }
                                });
                            } else {
                                arrays[0].forEach((elem) => {
                                    if (!elem.active || elem.archived) {
                                        let index = arrays[0].indexOf(elem);
                                        arrays[0].splice(index, 1);
                                    }
                                });
                            }
                            data = {
                                event: event,
                                condEx: event.type === "conduiteEx",
                                condLess: event.type === "conduiteLess",
                                categorie: event.type,
                                available: true,
                                action: "edit",
                                title: title,
                                fromCand: fromCandidat,
                                cands: [candidat],
                                cand: event.candidatId,
                                agent: event.agentResp,
                                agents: arrays[0],
                                centers: arrays[2],
                                vehicules: arrays[1],
                                vehicule: event.vehiculeId,
                                canEdit: true,
                                completed: event.completed,
                                cond_type: event.cond_type ? event.cond_type : null
                            };
                            this.openEventForm(data);
                        } else {
                            this.loadingService
                                .getOneCandidat(event.candidatId) //! [candidat]
                                .subscribe((cands) => {
                                    if (event.completed) {
                                        arrays[0].forEach((elem) => {
                                            if (elem.archived) {
                                                elem.username = "agent supprimé";
                                            }
                                        });
                                    } else {
                                        arrays[0].forEach((elem) => {
                                            if (!elem.active || elem.archived) {
                                                let index = arrays[0].indexOf(elem);
                                                arrays[0].splice(index, 1);
                                            }
                                        });
                                    }
                                    data = {
                                        event: event,
                                        condEx: event.type == "conduiteEx",
                                        condLess: event.type == "conduiteLess",
                                        categorie: event.type,
                                        available: true,
                                        action: "edit",
                                        title: title,
                                        fromCand: fromCandidat,
                                        cands: cands,
                                        cand: event.candidatId,
                                        agent: event.agentResp,
                                        agents: arrays[0],
                                        vehicules: arrays[1],
                                        centers: arrays[2],
                                        vehicule: event.vehiculeId,
                                        canEdit: true,
                                        completed: event.completed,
                                        cond_type: event.cond_type ? event.cond_type : null
                                    };
                                    this.openEventForm(data);
                                });
                        }
                    },
                    error:(err) => { }
                });
        } else {
            this.loadingService
                .getAgents0Vehicles1Centers2ConduiteLess()
                .subscribe({
                    next:(arrays: any) => {
                        if (fromCandidat) {
                            if (event.completed) {
                                arrays[0].forEach((elem) => {
                                    if (elem.archived) {
                                        elem.username = "agent supprimé";
                                    }
                                });
                            } else {
                                arrays[0].forEach((elem) => {
                                    if (!elem.active || elem.archived) {
                                        let index = arrays[0].indexOf(elem);
                                        arrays[0].splice(index, 1);
                                    }
                                });
                            }
                            data = {
                                event: event,
                                condEx: event.type === "conduiteEx",
                                condLess: event.type === "conduiteLess",
                                categorie: event.type,
                                available: true,
                                action: "edit",
                                title: title,
                                fromCand: fromCandidat,
                                cands: [candidat],
                                cand: event.candidatId,
                                agent: event.agentResp,
                                agents: arrays[0],
                                //       centers: arrays[2],
                                vehicules: arrays[1],
                                vehicule: event.vehiculeId,
                                canEdit: true,
                                completed: event.completed,
                                cond_type: event.cond_type ? event.cond_type : null
                            };
                            this.openEventForm(data);
                        } else {
                            this.loadingService
                                .getOneCandidat(event.candidatId) //! [candidat]
                                .subscribe((cands) => {
                                    if (event.completed) {
                                        arrays[0].forEach((elem) => {
                                            if (elem.archived) {
                                                elem.username = "agent supprimé";
                                            }
                                        });
                                    } else {
                                        arrays[0].forEach((elem) => {
                                            if (!elem.active || elem.archived) {
                                                let index = arrays[0].indexOf(elem);
                                                arrays[0].splice(index, 1);
                                            }
                                        });
                                    }
                                    data = {
                                        event: event,
                                        condEx: event.type == "conduiteEx",
                                        condLess: event.type == "conduiteLess",
                                        categorie: event.type,
                                        available: true,
                                        action: "edit",
                                        title: title,
                                        fromCand: fromCandidat,
                                        cands: cands,
                                        cand: event.candidatId,
                                        agent: event.agentResp,
                                        agents: arrays[0],
                                        vehicules: arrays[1],
                                        //       centers: arrays[2],
                                        vehicule: event.vehiculeId,
                                        canEdit: true,
                                        completed: event.completed,
                                        cond_type: event.cond_type ? event.cond_type : null
                                    };
                                    this.openEventForm(data);
                                });
                        }
                    },
                    error:(err) => { }
        });
        }

    }

    async editCode(event, title, fromCandidat, candidat?) {
        let data;
        let centers = [];
        if (event.type === "codeEx") {
            await this.loadingService
                .getCentersCode()
                .pipe(first())
                .toPromise()
                .then((res) => {
                    centers = res;
                });
        }
        if (fromCandidat) {
            data = {
                event: event,
                codeEx: event.type === "codeEx",
                codeLess: event.type === "codeLess",
                categorie: event.type,
                available: true,
                action: "edit",
                title: title,
                centreExamen: event.centreExamen,
                centers: centers,
                fromCand: fromCandidat,
                cands: [candidat],
                cand: event.candidatId,
                hasFrai: candidat.tentative !== 1,
                canEdit: true,
                completed: event.completed,
            };
            this.openEventForm(data);
        } else {
            this.loadingService
                .getOneCandidat(event.candidatId) //! [candidat]
                .subscribe((cands) => {
                    data = {
                        event: event,
                        codeEx: event.type == "codeEx",
                        codeLess: event.type == "codeLess",
                        categorie: event.type,
                        available: true,
                        action: "edit",
                        title: title,
                        fromCand: fromCandidat,
                        cands: cands,
                        centers: centers,
                        centreExamen: event.centreExamen,
                        cand: event.candidatId,
                        hasFrai: event.tentative !== 1,
                        canEdit: true,
                        completed: event.completed,
                    };
                    this.openEventForm(data);
                });
        }
    }
    editEvent(
        eventId,
        categorie: string,
        canEdit: boolean,
        fromCandidat,
        candidat?
    ) {
        this.loadingService.getEvent(eventId).subscribe((event) => {
            let title;
            if (event.type === "codeEx") title = "Examen Code";
            else if (event.type === "codeLess") title = "Séance Code";
            else if (event.type === "conduiteEx" && event.cond_type != 'parc') title = "Examen Conduite";
            else if (event.type === "conduiteEx" && event.cond_type == 'parc') title = "Examen Manoeuvre";
            else if (event.type === "conduiteLess") title = "Séance";
            else if(event.type=== "resTest") title = event.title;
            else if(event.type=== "busy") title = 'busy';

            if (canEdit) {
            if (categorie === "code") {
                    this.editCode(event, title, fromCandidat, candidat);
                } else if (categorie === "conduite") {
                    this.editConduite(event, title, fromCandidat);
                }
                // !Currently DOESNT WORK
                /* else if(categorie === "resTest"){
                    this.editReservation(event,title)
                } */
                else if(categorie === "busy"){
                    this.editBusy(event,"Occupé")
                }
            } 
            //! currently not activated
            else {
                let data: Data = {
                    event: event,
                    codeEx: false,
                    codeLess: false,
                    categorie: event.type,
                    available: true,
                    action: "edit",
                    title: title,
                    fromCand: fromCandidat,
                    cands: event.cands,
                    cand: event.candidatId,
                    hasFrai: !(fromCandidat && candidat.tentative === 1),
                      blockedDuration: 60,
            };
            }
        });
    }

    planExamCode(date, fromCandidat, candidat?) {
        if(candidat && candidat.type?.indexOf('Type ') == -1) {
            candidat = {...candidat,type : "Type " + candidat.type};
        }
        date = date.date;
        let data: Data = {
            codeEx: true,
            categorie: "codeEx",
            available: true,
            action: "new",
            title: "Examen Code",
            date: date,
            start: (moment(date).format('HH:mm') > this.globalService.getUser().heureOuverture)
                        ? moment(date).format('HH:mm')
                        : this.globalService.getUser().heureOuverture,
            fromCand: fromCandidat,
            hasFrai: !(fromCandidat && candidat.tentative === 1),
            blockedDuration: 60,
        };
        //? Not from candidat
        forkJoin([
            this.loadingService.getCentersCode(),
            this.loadingService.getDefaultBlockedTime('exam_code_duration')
        ])
        .subscribe((centers0DefaultBlocked1) => {
            console.log('centers0DefaultBlocked1',centers0DefaultBlocked1);
            //set center
            const centers = centers0DefaultBlocked1[0]
            const blockedDuration = centers0DefaultBlocked1[1]
            data.centers = centers;
            if (centers.length == 1) {
                data.center = centers[0].id;
            } else {
                data.center = null;
            }
            if (!fromCandidat) {
                this.loadingService.getCandidatCodeEx().subscribe((cands) => {
                    //set candidat
                    if (cands.length == 0) {
                        data.available = false;
                    } else {
                        data.cands = cands;
                        if (cands.length == 1) {
                            this.loadingService.getTarif().subscribe(res => {
                                switch (cands[0].type) {
                                    case 'Type A':
                                    case 'Type AA':
                                        data.renouvelement = res.typeACodeEx
                                        break;
                                    
                                    case 'Type AA':
                                        data.renouvelement = res.typeACodeEx
                                        break;

                                    case 'Type B':
                                        data.renouvelement = res.typeBCodeEx
                                        break;

                                    case 'Type C':
                                        data.renouvelement = res.typeCCodeEx
                                        break;

                                    case 'Type C+E':
                                        data.renouvelement = res.typeCECodeEx
                                        break;

                                    case 'Type D':
                                        data.renouvelement = res.typeDCodeEx
                                        break;

                                    case 'Type D1':
                                        data.renouvelement = res.typeDUnCodeEx
                                        break;

                                }
                            })

                            data.cand = cands[0].id;
                            data.titre = cands[0].username;
                            data.hasFrai = cands[0].tentCo !== 1;
                            /* if (cands[0].tentCo == 1) {
                            } */
                        } else {
                            this.loadingService.getTarif().subscribe(res => {
                                data.tarif = res
                                data.cand = null;
                            })
                            // data.tarif = res
                        }
                    }
                    data.blockedDuration = blockedDuration;
                    this.openEventForm(data);
                });
            }
            //? From Candidat !specifying the cands array and who to select
            else {
                this.loadingService.getTarif().subscribe(res => {

                    switch (candidat.type) {
                        case 'Type A':
                        case 'Type AA':
                            data.renouvelement = res.typeACodeEx
                            break;

                        case 'Type B':
                            data.renouvelement = res.typeBCodeEx
                            break;

                        case 'Type C':
                            data.renouvelement = res.typeCCodeEx
                            break;

                        case 'Type C+E':
                            data.renouvelement = res.typeCECodeEx
                            break;

                        case 'Type D':
                            data.renouvelement = res.typeDCodeEx
                            break;

                        case 'Type D1':
                            data.renouvelement = res.typeDUnCodeEx
                            break;

                    }
                    data.available = true;
                    data.cands = [candidat];
                    data.cand = candidat.id;
                    data.titre = candidat.username;
                    data.blockedDuration = blockedDuration;
                    this.openEventForm(data);
                })

            }
        });
    }

    planExamConduite(cond_type: 'circuit' | 'parc' | null | undefined , date, fromCandidat, candidat?) {
        if(date.date) date = date.date;
        if(candidat && candidat.type?.indexOf('Type ') == -1) {
            candidat = {...candidat,type : "Type " + candidat.type};
        }



        let data: Data = {
            frai: 0,
            renouvelement: 0,
            available: true,
            condEx: true,
            title: (cond_type && cond_type == 'parc') ? "Examen Manoeuvre" : "Examen Conduite",//title,//"Examen " + (cond_type != 'parc') ? 'Conduite' : 'Manoeuvre', // + (cond_type ? (' ' + cond_type ): ''),
            categorie: "conduiteEx",
            action: "new",
            date: date,
            cond_type: cond_type,
            start: (moment(date).format('HH:mm') > this.globalService.getUser().heureOuverture)
                        ? moment(date).format('HH:mm')
                        : this.globalService.getUser().heureOuverture,
            fromCand: fromCandidat,
            blockedDuration: 60,
        };

        console.log('DATATAT',data.title)

        this.loadingService
            .getAgents0Vehicles1Cands2Centers3Tarif4BlockedMinutes5ConduiteEx()
            .subscribe({
                next:(arrays) => {
                    if (this.globalService.getUser().role === "moniteur") {
                        this.globalService.getUser().username =
                        this.globalService.getUser().prenom +
                        " " +
                        this.globalService.getUser().nom;
                        data.agents = [this.globalService.getUser()];
                        data.agent = this.globalService.getUser().id;
                        data.vehicule = data.agents[0].vehiculeId
                    } else {
                        arrays[0].forEach((elem) => {
                            if (!elem.active || elem.archived) {
                                let index = arrays[0].indexOf(elem);
                                arrays[0].splice(index, 1);
                            }
                        });
                        data.agents = arrays[0];

                    }

                    //?Not available cands
                    if (arrays[2].length === 0) {
                        data.available = false;
                    }
                    //? Available
                    else {

                        //? From Candidat
                        if (fromCandidat) {
                            data.cands = [candidat];
                            data.cand = candidat.id;
                            console.log('data cands',data.cands)
                            console.log('data cands',candidat)
                            switch (candidat.type) {
                                case 'Type A':
                                case 'Type AA':
                                    data.frai = arrays[4].typeACondEx
                                    data.renouvelement = arrays[4].typeACodeEx
                                    break;
                                case 'Type B':
                                    data.frai = arrays[4].typeBCondEx
                                    data.renouvelement = arrays[4].typeBCodeEx
                                    break;
                                case 'Type C':
                                    data.frai = arrays[4].typeCCondEx
                                    data.renouvelement = arrays[4].typeCCodeEx
                                    break;
                                case 'Type C+E':
                                    data.frai = arrays[4].typeCECondEx
                                    data.renouvelement = arrays[4].typeCECodeEx
                                    break;
                                case 'Type D':
                                    data.frai = arrays[4].typeDCondEx
                                    data.renouvelement = arrays[4].typeDCodeEx
                                    break;
                                case 'Type D1':
                                    data.frai = arrays[4].typeDUnCondEx
                                    data.renouvelement = arrays[4].typeDUnCodeEx
                                    break;
                            }
                            data.agents.forEach((element) => {
                                if (element.id == data.cands[0].agentResp) {
                                    data.agent = data.cands[0].agentResp;
                                    data.vehicule = element.vehiculeId;
                                }
                            });
                        }
                        //? !FromCandidat
                        else {
                            if (this.globalService.getUser().role === "moniteur") {

                                let candsOfAmoniteur = []
                                arrays[2].forEach(element => {
                                    if (this.globalService.getUser().id == element.agentResp)
                                        candsOfAmoniteur.push(element)
                                });
                                data.cands = candsOfAmoniteur
                                if (data.cands.length > 0) {
                                    data.available = true;
                                } else {
                                    data.available = false;
                                }
                            } else {
                                data.cands = arrays[2];
                                data.available = true;

                            }
                            if (data.cands.length == 1) {
                                data.cand = data.cands[0].id;
                                switch (data.cands[0].type) {
                                    case 'Type A':
                                    case 'Type AA':
                                        data.frai = arrays[4].typeACondEx
                                        data.renouvelement = arrays[4].typeACodeEx
                                    case 'Type B':
                                        data.frai = arrays[4].typeBCondEx
                                        data.renouvelement = arrays[4].typeBCodeEx
                                    case 'Type C':
                                        data.frai = arrays[4].typeCCondEx
                                        data.renouvelement = arrays[4].typeCCodeEx
                                    case 'Type C+E':
                                        data.frai = arrays[4].typeCECondEx
                                        data.renouvelement = arrays[4].typeCECodeEx
                                    case 'Type D':
                                        data.frai = arrays[4].typeDCondEx
                                        data.renouvelement = arrays[4].typeDCodeEx
                                    case 'Type D1':
                                        data.frai = arrays[4].typeDUnCondEx
                                        data.renouvelement = arrays[4].typeDUnCodeEx
                                }
                                if (data.cands[0].agentResp != null && this.globalService.getUser().role != 'moniteur') {
                                    data.agents.forEach((element) => {
                                        if (
                                            element.id ==
                                            data.cands[0].agentResp
                                        ) {
                                            data.agent =
                                                data.cands[0].agentResp;
                                            data.vehicule = element.vehiculeId;
                                        }
                                    });
                                }
                            }
                            else {
                                data.cand = null;
                                data.tarif = arrays[4]
                            }
                        }



                        data.vehicules = arrays[1];
                        if (data.agent == null) {
                            if (data.agents.length == 1)
                                data.agent = data.agents[0].id;
                            else {
                                data.agent = null;
                            }
                        }
                        if (data.vehicule == null) {
                            if (data.vehicules.length == 1)
                                data.vehicule = data.vehicules[0].id;
                            else data.vehicule = null;
                        }
                        if (data.cand && data.agent && data.agents.length > 1)
                            data.titre =
                                data.cands[0].username +
                                " " +
                                data.agents[0].username;
                        else if(data.cand && data.agent && data.agents.length == 1){
                            data.titre = data.cands[0].username
                        }
                    }

                    data.centers = arrays[3];
                    if (data.centers.length == 1) {
                        data.center = data.centers[0].id;
                    } else {
                        data.center = null;
                    }
                    data.blockedDuration = arrays[5];
                    this.openEventForm(data);
                },
                error:(err) => { }
            });
    }

    planLessCode(date, fromCandidat?, candidat?) {
        date = date.date;
        let data: Data = {
            codeLess: true,
            categorie: "codeLess",
            available: false,
            action: "new",
            title: "Séance Code",
            date: date,
            blockedDuration: 60,
            start: (moment(date).format('HH:mm') > this.globalService.getUser().heureOuverture)
            ? moment(date).format('HH:mm')
            : this.globalService.getUser().heureOuverture,
            fromCand: fromCandidat,
        };
        forkJoin([
            this.loadingService.getCandidatCodeLess(),
            this.loadingService.getDefaultBlockedTime('less_code_duration')
        ])
        .subscribe((cands0BlockedDuration1) => {
            let cands = cands0BlockedDuration1[0];
            let blockedDuration = cands0BlockedDuration1[1];
            if (cands.length > 0) {
                data.available = true;
                if (fromCandidat) {
                    data.cands = [candidat];
                    data.cand = candidat.id;
                    data.titre = candidat.username;
                } else {
                    data.cands = cands;

                    if (cands.length === 1) {
                        data.cand = cands[0].id;
                        data.titre = cands[0].username;
                    } else {
                        data.cand = null;
                    }
                }
            }
            data.blockedDuration = blockedDuration
            this.openEventForm(data);
    });
    }

    planLessConduite(cond_type: null | undefined | 'circuit' | 'parc' | 'recyclage',date, fromCandidat?, candidat?) {
        date = date.date;
        let data: Data = {
            condLess: true,
            available: false,
            title: "Séance",
            categorie: "conduiteLess",
            action: "new",
            date: date,
            //* if the date is selected it's value is bigger than heureOuverture else use heureOuverture
            start: (moment(date).format('HH:mm') > this.globalService.getUser().heureOuverture)
                        ? moment(date).format('HH:mm')
                        : this.globalService.getUser().heureOuverture,
            fromCand: fromCandidat,
            cond_type:cond_type,
            blockedDuration: 60,
        };

        this.loadingService
            .getAgents0Vehicles1Cands2BlockedMinutes3ConduiteLess()
            .subscribe({
                next:(arrays) => {
                    if (this.globalService.getUser().role !== "moniteur") {
                        arrays[0].forEach((elem) => {
                        if (!elem.active || elem.archived) {
                            let index = arrays[0].indexOf(elem);
                            arrays[0].splice(index, 1);
                        }
                    });
                }
                    //? available cands
                    if (arrays[2].length > 0) {
                        if (this.globalService.getUser().role === "moniteur") {

                            let candsOfAmoniteur = []
                            arrays[2].forEach(element => {
                                if (this.globalService.getUser().id == element.agentResp)
                                    candsOfAmoniteur.push(element)
                            });
                            data.cands = candsOfAmoniteur
                            if (data.cands.length > 0) {
                                data.agent = this.globalService.getUser().id;
                             //   data.agents = arrays[0];
                                data.vehicule = this.globalService.getUser().vehiculeId;
                                data.available = true;
                                if (data.cands.length === 1) {
                                    data.cand = data.cands[0].id;
                                }
                            } else {
                                data.available = false;

                            }

                        } else {
                            console.log('conds',arrays[2]);
                            data.available = true;
                            //? Candidats
                            if (fromCandidat) {
                                data.cands = [candidat];
                            } else {
                                data.cands = cond_type == 'recyclage' ? arrays[2].filter(e=>e.phase == 'libre') : arrays[2].filter(e=>e.phase != 'libre');
                            }
                            if (data.cands.length === 1) {
                                data.cand = data.cands[0].id;
                                if (data.cands[0].agentResp != null) {
                                    arrays[0].forEach((element) => {
                                        if (element.id == data.cands[0].agentResp) {
                                            data.agent = data.cands[0].agentResp;
                                            data.agents = arrays[0];
                                            data.vehicule = element.vehiculeId;
                                        }
                                    });
                                }
                            } else data.cand = null;
                        }

                        //?agents

                        if (this.globalService.getUser().role === "moniteur") {
                            this.globalService.getUser().username =
                                this.globalService.getUser().prenom +
                                " " +
                                this.globalService.getUser().nom;
                            data.agents = [this.globalService.getUser()];
                            data.agent = this.globalService.getUser().id;

                            if (this.globalService.getUser().vehiculeId)
                                data.vehicule = this.globalService.getUser().vehiculeId;
                        } else if (data.agent == null) {
                            if (arrays[0].length === 1) {
                                data.agents = arrays[0];
                                data.agent = data.agents[0].id;
                                if (data.agents[0].vehiculeId)
                                    data.vehicule = data.agents[0].vehiculeId;
                            } else {
                                data.agents = arrays[0];
                                data.agent = null;
                            }
                        }
                        //?Vehicules
                        data.vehicules = arrays[1];
                        if (data.vehicule) {
                        } else if (arrays[1].length === 1) {
                            data.vehicule = data.vehicules[0].id;
                        } else data.vehicule = null;
                        data.date = date;
                        if (data.cand && data.agent && arrays[0].length > 1){
                            arrays[0].forEach((element) => {
                                if (element.id == data.agent) {
                                    data.titre =
                                        data.cands[0].username +
                                        " " +
                                        element.username;
                                }
                            });
                        }
                        else if(data.cand && data.agent && arrays[0].length == 1){
                            data.titre =
                                        data.cands[0].username
                        }
                    }
                    data.blockedDuration = arrays[3]
                    this.openEventForm(data);
                },
                error:(err) => { }
            }
            );
    }

    planBusy(date){
        date = date.date;
        let data: Data = {
            condLess: false,
            cond_type:null,
            available: true,
            title: "Occupé",
            categorie: "busy",
            action: "new",
            date: date,
            //* if the date is selected it's value is bigger than heureOuverture else use heureOuverture
            start: (moment(date).format('HH:mm') > this.globalService.getUser().heureOuverture)
                        ? moment(date).format('HH:mm')
                        : this.globalService.getUser().heureOuverture,
            fromCand: false,
            blockedDuration: 60,
        };
        this.loadingService
        .getAllActiveAgents()
        .subscribe({
            next:(agents)=>{
                    data.agents = this.globalService.getUser().role != "moniteur" 
                                            ? agents
                                            : agents.filter(elem=>elem.id = this.globalService.getUser().id)
                    data.agent = (agents.length > 0) ? agents[0].id : null 
                    data.titre = (agents.length > 0) ? (agents[0].prenom + " est occupé(e)"): null 
                    this.openEventForm(data);
                },
                error:(err) => { }
            }
            );
    }

    //! Adding from Cand
    //? Exam from Cand
    planExamCodeCand() { }

    planExamConduiteCand() { }

    //?Edit Exam From Cand
    editExamCodeCand() { }

    editExamConduiteCand() { }
}
//this.choseDialogRef.componentInstance.confirmMessage = msg;
