import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, of, ReplaySubject, switchMap } from "rxjs";
import { environment } from "environments/environment";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SetLocationComponent } from "./main/pop-ups/set-location/set-location.component";
import { GlobalService } from "app/global.service";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { LocationService } from "./main/pop-ups/set-location/location.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "./main/components/succes-snak-bar/succes-snak-bar.component";

@Injectable({
    providedIn: "root",
})
export class CommunicationPopupService {
    i = 0;
    uri = environment.uriG;


    constructor(
        public matDialogRef: MatDialogRef<SetLocationComponent>,
        private _matDialog: MatDialog,
        private _authService: AuthentificationService,
        private _locationService: LocationService,
        private snackBar: MatSnackBar,

    ) {}



    agenceLocationDialogStart(){
        this.matDialogRef = this._matDialog.open(SetLocationComponent, {
            panelClass: ["common-dialog-style", "common-dialog-large-style", "set-location-d-style"],
            data: {
                fromDash: true,
            },
            disableClose: true 
        });
    
        this.matDialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.snackBar.openFromComponent(SuccesSnakBarComponent, {
                    duration: 3000,
                });
            }
        });
    }

    showLocationPopup(){

        if (this._authService.loggedIn()) {
            setTimeout(()=>{
                this._locationService.checkIfAgenceHasLocation().subscribe({
                    next: (data)=> {
                        console.log('checkIfAgenceHasLocation' , data);
                        if (data.msg == 'not found') {
                            this.agenceLocationDialogStart();
                        }
                    },
                    error : (error) => {
                        console.log('error', error);
                    }
                })
                
            },4000);
        }
    }
}
