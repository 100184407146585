import { RealTimeService } from "./../../../real-time.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { Router } from "@angular/router";
import { LayoutService } from "./../../layout.service";
import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Input,
    Injector,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs";
import * as moment from "moment";
import { GlobalService } from "app/global.service";
import { SharedNavigationService } from "@fuse/services/shared-navigation.service";
import { AuthentificationService } from "app/main/authentification/authentification.service";

@Component({
    selector: "quick-panel",
    templateUrl: "./quick-panel.component.html",
    styleUrls: ["./quick-panel.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent implements OnInit, OnDestroy {
    notifications: any[] = [];
    badge: number;
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    layoutService;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private injector: Injector,
        private globalService: GlobalService,
        private _httpClient: HttpClient,
        private _sharedService: SharedNavigationService,
        private router: Router,
        private _fuseSidebarService: FuseSidebarService,
        private socketService: RealTimeService,
        private authService: AuthentificationService
    ) {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true,
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    loading() {
        this.layoutService = this.injector.get(LayoutService);

        this.layoutService.getNotifications().subscribe((qres: any) => {
            this.notifications = [];
            (qres.notification as any[]).forEach((element) => {
                element["type"] = "notification";
                this.notifications.push(element);
            });
            
                (qres.questions as any[]).forEach((element) => {
                element["type"] = "question";
                this.notifications.push(element);
            });

            // this.badge = qres.length;
            this.notifications = this.notifications.sort((a, b) => {
                let d = new Date(a.createdAt).getTime();
                let d1 = new Date(b.createdAt).getTime();
                return d1 - d;
            });
        });
    }
    /**
     * ? Subscribe to Questions Change
     * ? Subscribe to Notifications Change
     */
    updateSubscription() {
        this.socketService
            .getRealTimeQuestion()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((question) => {
                question["type"] = "question";
                this.notifications.unshift(question);
            });
        this.socketService
            .getRealTimeFollowUps()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((question) => {
                question["type"] = "question";
                this.notifications.unshift(question);
            });

        this.socketService
            .getRealTimeNotification()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notification) => {
                notification["type"] = "notification";
                this.notifications.unshift(notification);
            });
    }
    ngOnInit(): void {
        // Subscribe to the events
        if (this.authService.loggedIn()) {
            this.loading();
        }

        this._sharedService.loginNotification().subscribe((role) => {
            if (role !== "superadmin") this.loading();
        });
        this.updateSubscription();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true)
        this._unsubscribeAll.complete();
    }

    markRed(id) {
        this.layoutService.notificationRed(id).subscribe((res) => {
            this.notifications.forEach((elem) => {
                if (elem.id === id) elem.opened = true;
            });
        });
    }

    goToQuestion() {
        this.router.navigate(["/code/questions"]);
        this._fuseSidebarService.getSidebar("quickPanel").toggleOpen();
    }

    getDate(item) {
        return moment(item.createdAt ?? item.created_at).fromNow();
    }
}
